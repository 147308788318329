<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="fullname"
                  outlined
                  label="Name"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-if="allCategory.results"
                  :items="allCategory.results.list"
                  v-model="category"
                  item-text="title"
                  item-value="categoryHandle"
                  label="Job Category"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="lookingfors"
                  v-model="lookingfor"
                  label="Level"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="appliedforjobs"
                  v-model="applied"
                  label="Job Applied"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table" v-if="!allLoadingResume">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Resume Number</th>
                  <th class="text-left">Full Name</th>
                  <th class="text-left">Category</th>

                  <th class="text-left">Email</th>
                  <th class="text-left">Phone</th>
                  <th class="text-left">Level</th>
                  <th class="text-left">Share CV</th>
                  <th class="text-left">Applied For</th>
                  <th class="text-left">CV</th>
                  <th class="text-left">Status</th>
                  <!-- <th class="text-right" colspan="2">
                    Action
                  </th> -->
                </tr>
              </thead>
              <tbody v-if="allUploadedResume.results">
                <tr
                  v-for="(item, index) in allUploadedResume.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.resume_number }}</td>
                  <td>{{ item.fullname }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.experiencelevel }}</td>
                  <td>
                    <v-switch v-model="item.shareCV"></v-switch>
                  </td>
                  <td>
                    <div v-if="item.appliedfor">
                      <a
                        :href="`https://necojobs.com.np/job/${item.appliedfor}`"
                        target="_blank"
                        >Show Job</a
                      >
                    </div>
                    <div v-if="!item.appliedfor">Uploaded CV</div>
                  </td>
                  <td>
                    <a
                      :href="`https://app.necojobs.com.np${item.resume_file}`"
                      target="_blank"
                      >Download</a
                    >
                  </td>
                  <td>
                    <v-btn
                      @click="updateStatusCV(item._id)"
                      class="submitbutton btn-dark"
                      :disabled="!item.status"
                    >
                      <span v-if="item.status">Active</span>
                      <span v-if="!item.status">DeActive</span>
                    </v-btn>
                  </td>

                  <!-- <td class="text-right">
                    <v-icon class="icons-action"> mdi-pencil </v-icon>
                    <v-icon class="icons-action"> mdi-delete </v-icon>
                    <v-icon class="icons-action"> mdi-download </v-icon>
                    
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="
              allUploadedResume.results &&
              allUploadedResume.results.pagination.total <= 0 &&
              !allLoadingResume
            "
            class="nodatabox"
          >
            No Data Found
          </div>
          <div v-if="allLoadingResume">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul
            class="pagination-button"
            v-if="allUploadedResume.results != undefined"
          >
            <li>
              Page :
              <span v-if="allUploadedResume.results.pagination.next">{{
                (allUploadedResume.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allUploadedResume.results.pagination.next">{{
                allUploadedResume.results.pagination.total
              }}</span>
              -
              {{ allUploadedResume.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allUploadedResume.results.pagination.previous"
                  @click="
                    prevPage(allUploadedResume.results.pagination.previous)
                  "
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allUploadedResume.results.pagination.next"
                  @click="nextPage(allUploadedResume.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CV",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "CV's List",
      addlink: "/dashboard/cv/add",
    },
    valid: true,
    educations: ["SLC", "10+2", "Bachelor", "Master", "PHD"],
    availableFor: "",
    lookingfor: "",
    lookingfors: ["Top Level", "Senior Level", "Mid Level", "Entry Level"],
    appliedforjobs: ["Yes", "No"],
    applied: "",
    availableFors: [
      "Full Time",
      "Part Time",
      "Temporary",
      "Contract",
      "Freelance",
      "Traineership",
      "Internship",
      "Volunteer",
    ],
    category: "",
    education: "",
    fullname: "",
    adsposition: "",
    desserts: [
      {
        sn: 1,
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        sn: 2,
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        sn: 3,
        name: "Eclair",
        calories: 262,
      },
      {
        sn: 4,
        name: "Cupcake",
        calories: 305,
      },
    ],

    limit: 100,
    skip: 1,
    filter: {},
  }),
  methods: {
    ...mapActions([
      "uploadedResume",
      "fetchCategory",
      "filterCV",
      "updateStatus",
    ]),
    nextPage(data) {
      this.filterCV({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: data },
      });
    },
    prevPage(data) {
      this.filterCV({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: data },
      });
    },
    updateStatusCV(data) {
      this.updateStatus({ _id: data });
    },
    validate() {
      this.filter = {};
      if (this.fullname != "") {
        this.filter.fullname = {
          $regex: this.fullname,
          $options: "i",
        };
      }
      if (this.category != "") {
        this.filter.category = this.category;
      }

      if (this.lookingfor != "") {
        this.filter.experiencelevel = this.lookingfor;
      }

      if (this.applied == "Yes") {
        this.filter.appliedfor = { $exists: true };
      }
      if (this.applied == "No") {
        this.filter.appliedfor = { $exists: false };
      }

      this.filterCV({
        resumefilter: JSON.stringify(this.filter),
        ...{ limit: this.limit, skip: this.skip },
      });
    },
  },
  computed: {
    ...mapGetters(["allUploadedResume", "allCategory", "allLoadingResume"]),
  },
  created() {
    this.uploadedResume({ limit: this.limit, skip: this.skip });
    this.fetchCategory();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
